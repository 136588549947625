import React, { Component } from 'react'

export class ExpandedGrid extends Component {
  render() {
    const { className = '', children, ...props } = this.props
    return (
      <div className={`row expanded align-center ${className}`} {...props}>
        <div className="small-12 px-0 columns">{children}</div>
      </div>
    )
  }
}

export class RegularGrid extends Component {
  render() {
    const { className = '', children, ...props } = this.props
    return (
      <div className={`row align-center ${className}`} {...props}>
        <div className="small-12 columns">{children}</div>
      </div>
    )
  }
}

export class NarrowGrid extends Component {
  render() {
    const { className = '', children, ...props } = this.props
    return (
      <div className={`row align-center ${className}`} {...props}>
        <div className="small-12 medium-9 large-7 columns">{children}</div>
      </div>
    )
  }
}

export class CustomGrid extends Component {
  render() {
    const { className = '', columns = '', children, ...props } = this.props
    return (
      <div className={`row align-center ${className}`} {...props}>
        <div className={`${columns} columns`}>{children}</div>
      </div>
    )
  }
}
