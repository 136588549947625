import React, { Component } from 'react'
import Layout from '../layouts/Layout'
import { ExpandedGrid, RegularGrid, CustomGrid } from '../layouts/Grid'
import _ from 'lodash'
import CCLogo from '../images/colorcode-logo.svg'
import ButtonModal from '../components/ButtonModal'

import { Link, withPrefix } from 'gatsby'

import 'aos/dist/aos.css'
import { graphql } from 'gatsby'

export class HomePageTemplate extends Component {
  state = {
    joinLink: "https://www.getup.org.au/campaigns/colour-code/join-colour-code/our-power-together"
  }
  renderHero = hero => (
    <PageGrid className="hero--container">
      <img src={CCLogo} className="cc-logo mb-3" />
      <hr />
      <h1 className="hero--title xbold primary-colour mt-5 mb-3">
        <span>{hero.title}</span>
      </h1>
      <p
        className="mb-5 light"
        style={{ fontSize: '1.2rem', color: '#333', lineHeight: '1.3' }}
      >
        {hero.subtitle}
      </p>
      <ButtonModal
        href={this.state.joinLink}
        buttonText={hero.buttonName}
        className="btn"
      />
      {/* <form className="hero--get-involved">
        <div className="form-email--wrapper">
          <input id="email" type="text" />
          <label for="email"> Email </label>
        </div>
        <a href="#" className="btn btn-flex">
          {hero.buttonName}
        </a>
      </form> */}
    </PageGrid>
  )

  renderImageSection = imageSection => (
    <>
      <ExpandedGrid>
        <div className="images--container mt-5">
          <div className="colour-bar--container">
            <div className="colour-bar--primary" />
            <div className="colour-bar--secondary" />
          </div>
          <RegularGrid>
            <div className="images--wrapper">
              <div>
                <img src={imageSection.leftImage} />
              </div>
              {/* <div>
                <img src={imageSection.rightImage} />
              </div> */}
            </div>
          </RegularGrid>
        </div>
      </ExpandedGrid>
      <ExpandedGrid className="secondary-background">
        <PageGrid>
          <div className="blurb--container">
            <p className="h3 mb-0">{imageSection.blurb}</p>
          </div>
        </PageGrid>
      </ExpandedGrid>
    </>
  )

  renderCampaigns = campaigns => (
    <PageGrid>
      <h2 className=" h3 secondary-heading mt-5">{campaigns.title}</h2>
      <p>{campaigns.subtitle}</p>
      <div className="campaigns--grid my-5">
        <Campaign content={campaigns.primaryCampaign} primary />
        <Campaign content={campaigns.campaignTwo} />
        <Campaign content={campaigns.campaignThree} />
      </div>
    </PageGrid>
  )

  renderCallToAction = callToAction => (
    <PageGrid>
      <div className="cta--container">
        <h2 className="h3 bold mb-1">{callToAction.title}</h2>
        <p>{callToAction.subtitle}</p>
        {/* <form className="hero--get-involved">
          <div className="form-email--wrapper">
            <input id="email" type="text" />
            <label for="email"> Email </label>
          </div>
          <a href="#" className="btn btn-flex  btn-secondary">
            {callToAction.buttonName}
          </a>
        </form> */}
              <ButtonModal
        href={this.state.joinLink}
        buttonText={callToAction.buttonName}
        className="btn btn-white"
      />

          

      </div>
    </PageGrid>
  )

  render() {
    const { hero, imageSection, campaigns, callToAction } = this.props.content
    return (
      <>
        {this.renderHero(hero)}
        {this.renderImageSection(imageSection)}
        {this.renderCampaigns(campaigns)}
        {this.renderCallToAction(callToAction)}
        <ExpandedGrid>
          <img src="/uploads/no-racism-in-politics.jpg" className="footer-image" />
        </ExpandedGrid>
      </>
    )
  }
}

const Campaign = ({ content, ...props }) => {
  return (
    <div className={'campaign ' + (props.primary ? 'primary' : '')}>
      <img src={content.image} />
      <div className="text--wrapper">
        <a href={content.link} target="_blank">
          <h3 className={'bold mb-1 ' + (props.primary ? 'h4' : 'p')}>
            {content.title}
          </h3>
        </a>
        <p className="mb-0">{content.subtitle}</p>
        <a
          href={content.link}
          className="btn btn-tiny btn-outline btn-secondary mt-3"
          target="_blank"
        >
          {content.buttonName}
        </a>
      </div>
    </div>
  )
}

const PageGrid = ({ children, className = '' }) => (
  <div className={`row align-center ${className}`}>
    <div className="small-12 medium-10 large-8 columns">{children}</div>
  </div>
)

const HomePage = ({ data }) => {
  const content = data.markdownRemark.frontmatter
  return (
    <Layout>
      <HomePageTemplate content={content} />
    </Layout>
  )
}

export default HomePage

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        hero {
          title
          subtitle
          buttonName
        }
        imageSection {
          leftImage
          rightImage
          blurb
        }
        campaigns {
          title
          subtitle
          primaryCampaign {
            image
            title
            subtitle
            link
            buttonName
          }
          campaignTwo {
            image
            title
            subtitle
            link
            buttonName
          }
          campaignThree {
            image
            title
            subtitle
            link
            buttonName
          }
        }
        callToAction {
          title
          subtitle
          buttonName
        }
      }
    }
  }
`
